<template>
  <Layout :pagetitle="'Users'">
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header">
            <h4 class="card-title mb-0">Lista de Usuários</h4>
          </div>
          <div class="card-body">
            <div v-if="isLoading" class="d-flex justify-content-center">
              <div
                :class="`spinner-border m-1 text-${company.color ? company.color : 'primary'}`"
                role="status"
              >
                <span class="sr-only">Loading...</span>
              </div>
            </div>
            <div v-else class="table-responsive">
              <table class="table align-middle mb-0">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Nome</th>
                    <th>E-mail</th>
                    <th>Data Nascimento</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(user, index) in users" :key="user._id">
                    <th scope="row">{{ index + 1 }}</th>
                    <td>{{ user.name }}</td>
                    <td>{{ user.email }}</td>
                    <td>{{ $utils.formatDate(user.dateOfBirth) }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row justify-content-center">
      <div class="d-flex justify-content-end">
        <button
          :class="`btn btn-rounded waves-effect waves-light col-6 col-md-2 btn-${company.color ? company.color : 'primary'}`"
          @click="$router.push({ name: 'companyPrincipal' })"
        >
          <span>Voltar</span>
        </button>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import { mapActions, mapState } from "vuex";

export default {
  data() {
    return {
      company: JSON.parse(localStorage.getItem("company")),
      users: [],
    };
  },
  components: {
    Layout,
  },
  computed: {
    ...mapState(["isLoading"]),
  },
  created() {},
  async mounted() {
    if (this.company._id) {
      this.setLoading(true);
      const users = await this.$utils.getByCompany("user", this.company._id);
      this.setLoading(false);
      if (users) {
        this.users = users;
      }
    }
  },
  methods: {
    ...mapActions(["setLoading"]),
  },
};
</script>

<style scoped></style>
